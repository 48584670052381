<template>
    <div class="information_page" v-if="page != null">
        <!-- <h4>{{ page.title }}</h4> -->
        <div v-html="page.body"></div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: 'InformationPage',
    metaInfo() {
        if (this.page == null) { return {meta: []} }
		return { 
            title: this.page.title,
			meta: [
				{
                    vmid: "og:title",
					property: "og:title",
					content: this.page.title
				},
				{
                    vmid: "og:site_name",
					property: "og:site_name",
					content: "SportlerPlus"
				},
				{
                    vmid: "og:type",
					property: "og:type",
					content: "article"
				}
			]
		}
	},
    data() {
        return {
            page: null,
        }
    },
    methods: {
        init(pageId) {
            let apiUrl = this.$store.getters.getApiUrl;
            axios
            .get(apiUrl + 'pages/' + pageId + "/")
            .then(response => {
                this.page = response.data;
                this.$emit('setModalTitle', this.page.title);
            });
        },
    },
    components: {

    },
    computed: {

    },
    mounted() {
        this.init(this.$route.params.id);
    },

}
</script>
<style lang="scss">
	.information_page {
		h4 {
			margin: 26px 0 11px;
		}
    p {
      overflow-wrap: break-word;
      opacity: 1;
      color: rgba($colorBlack, .64);
    }
    a {
      color: $colorPrimary;
      font-weight: 700;
    }
    ul {
      font-size: 14px;
      line-height: 24px;
      font-weight: 500;
      color: rgba($colorBlack, .64);
      padding-left: 18px;

      li {
        &:not(:last-child) {
          margin-bottom: 8px;
        }
      }
    }
    ol {
      font-size: 14px;
      line-height: 24px;
      font-weight: 500;
      color: rgba($colorBlack, .64);
      margin-bottom: 16px;

      a {
        color: rgba($colorBlack, .64);
      }

      b {
        font-weight: 500;
      }
    }
	}
</style>